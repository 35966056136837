import { apiClient } from '@/api';
import { refreshAccessToken } from '@/contexts/accountingContext/services';
import loggerInstance, { turnOffLogger } from '@/logger';
import store from '@/store';
import { showErrorNotification } from '@/utils';
import { getNowMs, getMsFromDate } from '@/utils/dateUtils';
import { SET_TOKENS } from '@/store/modules/app/mutation-types';
import { RESET_AUTH_STATE } from '@/store/modules/app/action-types';
import { TDomainError } from '@/types';

import { EErrorEvents } from './constants';
import {
  TReachedResponse,
  TOriginalRequestOptions,
} from './types';

const logger = import.meta.env.VITE_APP_SEND_API_REQUEST_LOGGER_ENABLED === 'true'
  ? loggerInstance
  : turnOffLogger(loggerInstance);

const handleResponseError = (error: any) => {
  if (!error?.errorEvent) throw error;

  switch (error.errorEvent) {
    case EErrorEvents.authenticationFailed: {
      // TODO: удалить после решения проблемы с разлогином
      logger.log('[handleResponseError] authenticationFailed');
      store.dispatch(`app/${RESET_AUTH_STATE}`);
      throw error;
    }
    case EErrorEvents.maintenanceMode:
      store.dispatch('app/setMaintenanceMode');
      throw error;
    case EErrorEvents.unexpectedError:
      logger.warn('[sendApiRequest] Unexpected error');
      throw error;
    case EErrorEvents.applicationError:
      throw error.error;
    default:
      throw error;
  }
};

let accessTokenRefreshingPromise: null | Promise<Record<string, unknown>> = null;

const resolveAfterRefreshTokens = (resolve: any, reject: any) => {
  if (!accessTokenRefreshingPromise) {
    logger.warn('[resolveAfterRefreshTokens]. There is no promise to resolve.');
    return reject();
  }

  return accessTokenRefreshingPromise
    .then(resolve)
    .catch(reject)
    .finally(() => {
      accessTokenRefreshingPromise = null;
    });
};

export const getActualAuthToken = (withoutTokens = false) => new Promise((resolve, reject) => {
  if (withoutTokens) {
    resolve({});
    return;
  }

  const authTokens = store.state.app;
  const { accessTokenExpiresAt, refreshTokenExpiresAt } = authTokens;

  const accessTokenExpiresAtMs = accessTokenExpiresAt ? getMsFromDate(accessTokenExpiresAt) : 0;
  const refreshTokenExpiresAtMs = refreshTokenExpiresAt ? getMsFromDate(refreshTokenExpiresAt) : 0;
  /** Добавляем 1 минуту (60000 мс), чтобы заранее обновлять токены в случае, когда очередь запросов забивается
      и бек не успевает обработать вовремя смену токена */
  const now = getNowMs() + 1000 * 60;

  const resolveWithTokens = () => {
    resolve(authTokens);
  };

  // Проверяем, что мы собираемся идти в API со свежими токенами, и если что, обновляем их
  if (now > refreshTokenExpiresAtMs) {
    // TODO: удалить после решения проблемы с разлогином
    logger.log('[resolveWithTokens] now > refreshTokenExpiresAtMs', {
      now,
      refreshTokenExpiresAtMs,
    });
    // Если протух токен для рефреша, то очищаем стор. Нужно заново залогиниться.
    logger.warn('[sendApiRequest] Refresh token is expired, set application state to unauthenticated...');
    store.dispatch(`app/${RESET_AUTH_STATE}`);
    // eslint-disable-next-line prefer-promise-reject-errors
    reject({
      internal: true,
      reason: 'Tokens are expired',
    });
  } else if (now > accessTokenExpiresAtMs) {
    // если уже запущен запрос на рефреш токена, то ждём, пока зарезолвится его промис
    if (accessTokenRefreshingPromise) {
      resolveAfterRefreshTokens(resolve, reject);
      return;
    }

    // Если протух токен для доступа, обновляем токен.
    logger.warn('[sendApiRequest] Access token is expired, let\'s exchange token to new one...');
    const { refreshToken } = store.state.app;
    if (!refreshToken) return;

    accessTokenRefreshingPromise = new Promise((resolveInside, rejectInside) => {
      refreshAccessToken({ refreshToken })
        .then((newAuthTokens) => {
          if (newAuthTokens) {
            store.commit(`app/${SET_TOKENS}`, newAuthTokens);
          }
          resolveAfterRefreshTokens(resolve, reject);
          resolveInside(newAuthTokens || {});
        })
        .catch((error) => {
          logger.warn(`[sendApiRequest] Error while trying to refresh token with errorEvent = ${error?.errorEvent}.`);
          rejectInside(error);
          // TODO: удалить после решения проблемы с разлогином
          logger.log(`[sendApiRequest] Error while trying to refresh token with errorEvent = ${error?.errorEvent}.`);
          store.dispatch(`app/${RESET_AUTH_STATE}`);
        });
    });
  } else {
    resolveWithTokens();
  }
}).catch(() => {});

const mixinCurrentTenant = (request: any) => {
  if (!request.endpoint.includes(':tenant_id')) return request;

  const isAuthByEntryCode = store.getters['app/isAuthByEntryCode'];
  const tenantId = isAuthByEntryCode ? store.getters['app/decodedJWT']?.tenant_id : store.getters['tenants/currentTenantId'];

  if (!tenantId) {
    throw new Error('No tenantId found');
  }

  return {
    ...request,
    endpoint: request.endpoint.replace(':tenant_id', tenantId),
  };
};

const sendUsingInstance = (
  apiInstance: typeof apiClient.internal | typeof apiClient.admin,
) => <T = any>(originalRequest: TOriginalRequestOptions): Promise<TReachedResponse<T>> => {
  const sendRequest = (request: TOriginalRequestOptions) => apiInstance
    .sendRequest<T>(request)
    .catch(handleResponseError);

  const handleCommonError = (catchError: any) => {
    const {
      message = '',
      errors = '',
      code = '',
      meta = null,
    } = catchError?.response?.data || {};
    const { status = null } = catchError?.response || {};
    const newError: TDomainError = {
      status,
      message,
      errors,
      code,
      meta,
    };
    // для обратной совместимости со старыми requestOptions
    if (originalRequest.requestOptions?.showError) {
      showErrorNotification({
        options: originalRequest.requestOptions,
        ...newError,
      });
    }
    if (catchError?.internal) throw new Error(catchError.reason);
    throw catchError?.response ? newError : catchError;
  };

  return getActualAuthToken(originalRequest.withoutAuth)
    .then((authTokens) => ({
      ...originalRequest,
      authTokens,
    }))
    .then(mixinCurrentTenant)
    .then(sendRequest)
    .catch(handleCommonError);
};

export const sendApiRequest = sendUsingInstance(apiClient.internal);
export const sendAdminApiRequest = sendUsingInstance(apiClient.admin);
