import {
  ComputedRef,
  Ref,
  computed,
} from 'vue';

import useStore from '@/store/useStore';
import useViewState from '@/composables/useViewState';
import { TDomainError, TSubscription } from '@/types';
import { createBillingAccount, updatePlan } from '@/contexts/billingContext/services';
import { EBillingPlanCode, EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';
import { EBillingPlanSelectionSteps } from '@/contexts/billingContext/components/BillingPlanSelectionModal/domain/constants';
import { TChangePlanSelectionStepFunction } from '@/contexts/billingContext/components/BillingPlanSelectionModal/domain/types';

import { TBillingShipperForm } from '../components/BillingShipperForm/domain/types';
import BillingShipperForm from '../components/BillingShipperForm/index.vue';
import BillingCarrierForm from '../components/BillingCarrierForm/index.vue';
import { TBillingCarrierForm } from '../components/BillingCarrierForm/domain/types';

type TUseView = {
  shipperFormRef: Ref<InstanceType<typeof BillingShipperForm> | null>,
  carrierFormRef: Ref<InstanceType<typeof BillingCarrierForm> | null>,
  planCode: Ref<EBillingPlanCode | null>,
  isShipperFormShown: ComputedRef<boolean>,

  emit: (event: 'close', ...args: unknown[]) => void,
  changePlanSelectionStep: TChangePlanSelectionStepFunction,
};

export const useConnectPlan = (
  {
    shipperFormRef,
    carrierFormRef,
    planCode,
    isShipperFormShown,

    emit,
    changePlanSelectionStep,
  }: TUseView,
) => {
  const store = useStore();

  const activeSubscription = computed(() => store.state.tenants.currentTenantInfo?.subscriptions?.find(
    (plan: TSubscription) => plan.status === EBillingPlanCodeStatus.active));

  const { isLoading, setViewStateAs } = useViewState();

  const isSubmitDisabled = computed(
    () => !!(shipperFormRef.value?.isSubmitDisabled || carrierFormRef.value?.isSubmitDisabled),
  );

  const currentRef = computed(() => (isShipperFormShown.value ? shipperFormRef.value : carrierFormRef.value));

  const updateBillingPlan = (
    payload: TBillingShipperForm | TBillingCarrierForm,
    planCodeValue: EBillingPlanCode,
  ) => {
    updatePlan({
      planCode: planCodeValue,
      ...payload,
    })
      .then(() => {
        changePlanSelectionStep(EBillingPlanSelectionSteps.completeConnection);
      })
      .catch((errors: TDomainError | null) => {
        if (errors) {
          currentRef.value?.setResponseErrors(errors);
        }
      });
  };

  const createBillingPlan = (
    payload: TBillingShipperForm | TBillingCarrierForm,
    planCodeValue: EBillingPlanCode,
  ) => {
    createBillingAccount({
      planCode: planCodeValue,
      ...payload,
    })
      .then(() => {
        // Здесь идет проверка на planCode.value !== EBillingPlanCode.cargoOwner,
        // потому что у cargoOwner нет последнего шага (экран с большим пальцем и кнопкой "Начать пользоваться"),
        // поэтому диспатч и закрытие модального окна должны происходить на этом этапе.
        // У всех остальных планов есть последний шаг, и диспатч и закрытие модального окна происходят там.
        if (planCode.value !== EBillingPlanCode.cargoOwner) {
          changePlanSelectionStep(EBillingPlanSelectionSteps.completeConnection);
        } else {
          store.dispatch('tenants/fetchCurrentTenantInfo');

          emit('close');
        }
      })
      .catch((errors: TDomainError | null) => {
        if (errors) {
          currentRef.value?.setResponseErrors(errors);
        }
      });
  };

  const onSubmit = () => {
    const planCodeValue = planCode.value;

    if (!planCodeValue) {
      return;
    }

    setViewStateAs.loading();

    currentRef.value?.onSubmit()
      .then((payload: TBillingShipperForm | TBillingCarrierForm) => {
        if (activeSubscription.value) {
          updateBillingPlan(payload, planCodeValue);
        } else {
          createBillingPlan(payload, planCodeValue);
        }
      },
      )
      .catch(() => {})
      .finally(setViewStateAs.done);
  };

  return {
    isLoading,
    isSubmitDisabled,

    onSubmit,
  };
};
