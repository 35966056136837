<template>
  <span
    class="input"
    :class="[
      className,
      `input_${size}`,
    ]"
  >
    <input
      :id="id"
      ref="inputRef"
      class="input__inner"
      :class="`input__inner_${size}`"
      :placeholder="placeholder || ''"
      :value="value"
      :disabled="disabled"
      :type="type"
      :readonly="readonly"
      :autocomplete="autocomplete"
      :inputmode="inputmode"
      :maxlength="!!maxLength ? maxLength : undefined"
      @input="onInput"
      @change="onChange"
      @paste="$emit('paste', $event)"
      @keyup.enter="$emit('enter', $event)"
      @keyup.delete="onClear"
      @keydown="$emit('keydown', $event)"
      @blur="blur"
      @focus="focus"
    />
    <button
      v-if="isShowClearIcon"
      class="input__clear-button"
      @click="clearValue"
    >
      <component :is="closeSvgComponent" />
    </button>
  </span>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  ref,
} from 'vue';
import type { PropType } from 'vue';
import { Form } from 'ant-design-vue';

import {
  ESize,
  EInputTypes,
  EInputModes,
} from '@/ui/types';
import MediumCloseSvg from '@/assets/svg/16x16/close.svg';
import LargeCloseSvg from '@/assets/svg/20x20/close.svg';
import { getNumberWithLimitedDecimalPart } from '@/ui/Input/utils/getNumberWithLimitedDecimalPart';

import { getNumberFromValue } from '../NumberInput/utils/getNumberFromValue';

export default defineComponent({
  name: 'Input',
  components: {
    MediumCloseSvg,
    LargeCloseSvg,
  },
  props: {
    /** тип инпута */
    type: {
      type: String as PropType<EInputTypes>,
      default: EInputTypes.text,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, null] as PropType<string | number | null>,
      default: null,
    },
    /** для задания размеров инпута */
    size: {
      type: String as PropType<ESize>,
      default: ESize.medium,
    },
    /** для подсветки инпута при не валидных данных */
    isInvalid: {
      type: Boolean,
      default: false,
    },
    /** для контроля отображения бордера у инпута */
    hasBorder: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    inputmode: {
      type: String as PropType<EInputModes>,
      default: EInputModes.text,
    },
    /** отображение иконки очистки поля */
    allowClear: {
      type: Boolean,
      default: false,
    },
    /** валидация значения инпута на цифры */
    isDigital: {
      type: Boolean,
      default: false,
    },
    /** ораничение символов в инпут */
    maxLength: {
      type: [Number, undefined] as PropType<number | undefined>,
      default: undefined,
    },
    /** ораничение цифр после запятой */
    digitsAfterComma: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
  },
  emits: [
    /** событие вызывается сразу же при изменении значения поля */
    'input',
    /** срабатывает когда значение поля изменено, но при условии снятия с него фокуса */
    'change',
    /** событие вызывается при нажатии на клавишу "Enter" */
    'enter',
    /** событие вызывается при вставке чего-либо в поле */
    'paste',
    /** событие вызывается при нажатии на клавишу */
    'keydown',
    /** событие вызывается каждый раз при расфокусировке поля */
    'blur',
    /** событие вызывается когда поле в фокусе */
    'focus',
    /** поддержка двустороннего связывания (v-model) */
    'update:value',
    /** событие вызывается когда поле очищено по кнопке  */
    'reset',
    'clear',
  ],
  setup(props, { emit, expose }) {
    /**
     * Для того, чтобы FormItem из Ant продолжал валидировать поля и отображать ошибки для компонентов из @/ui
     * необходимо получить из useInjectFormItemContext набор методов, которые необходимо вызывать
     * внутри аналогичных методов в компонентах @/ui.
     */
    const { onFieldChange, onFieldBlur, id } = Form.useInjectFormItemContext();
    const inputRef = ref<HTMLInputElement>();
    const isFocused = ref<boolean>(false);
    const isShowClearIcon = ref<boolean>(props.allowClear && !!props.value);

    const closeSvgComponent = computed(() => {
      if ([ESize.medium, ESize.small].includes(props.size)) {
        return 'MediumCloseSvg';
      }
      if (props.size === ESize.large) {
        return 'LargeCloseSvg';
      }
      return '';
    });

    const className = computed(() => {
      let resultClassName = props.disabled ? 'input_disabled' : '';

      if (props.isInvalid) {
        resultClassName += ' input_invalid';
      }

      if (!props.hasBorder) {
        resultClassName += ' input_no-border';
      }

      if (props.readonly) {
        resultClassName += ' input_readonly';
      }

      if (isFocused.value) {
        resultClassName += ' input_focused';
      }

      return resultClassName;
    });

    const onInput = (event: Event) => {
      const input = event.target as HTMLInputElement;

      if (!input.value) {
        emit('update:value', '');
        emit('input', event);

        return;
      }

      /** При включенной валидации на цифры */
      if (props.isDigital) {
        const validatedValue = getNumberFromValue(input.value, true);

        /** Сэтим пустую строку в инпут при не валидном символе */
        if (!validatedValue || !validatedValue.value) {
          input.value = '';
          emit('update:value', '');
          emit('input', event);

          return;
        }

        /** Присваиваем валидное значение в инпут */
        input.value = validatedValue.value;
      }

      /** При включенном ограничении на количество знаков после запятой */
      if (props.digitsAfterComma) {
        input.value = getNumberWithLimitedDecimalPart(input, props.digitsAfterComma);
      }

      emit('update:value', input.value);
      emit('input', event);

      isShowClearIcon.value = !!(input.value && props.allowClear);
    };

    const onChange = (event: Event) => {
      const input = event.target as HTMLInputElement;

      emit('update:value', input.value);
      emit('change', event);
      onFieldChange();
    };

    const blur = (event: Event) => {
      const input = event.target as HTMLInputElement;

      emit('update:value', input.value);
      inputRef.value?.blur();
      isFocused.value = false;
      emit('blur', event);
      onFieldBlur();
    };

    const focus = (event: Event) => {
      inputRef.value?.focus();
      isFocused.value = true;
      emit('focus', event);

      const endOfValue = inputRef.value?.value.length || null;
      // Двигаем курсор при фокусе на инпут - на конец value
      // обязательно с начала input.focus(), потом input.setSelectionRange()
      // иначе на мобильных браузерах будет ломаться
      inputRef.value?.setSelectionRange(endOfValue, endOfValue);
    };

    const clearValue = (event: Event) => {
      isShowClearIcon.value = false;
      emit('input', event);
      emit('change', event);
      emit('update:value', '');
      emit('reset');
    };

    const onClear = (event: KeyboardEvent) => {
      if (event.code === 'Backspace' && !inputRef.value?.value) {
        isShowClearIcon.value = false;
      }

      emit('clear', event);
    };

    expose({
      focus,
      blur,
      input: inputRef,
    });

    return {
      id,
      className,
      inputRef,
      isShowClearIcon,
      closeSvgComponent,

      onClear,
      clearValue,
      onChange,
      onInput,
      focus,
      blur,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
