import { defineStore } from 'pinia';
import { ref } from 'vue';

import { getActionProgress } from '@/services';
import { showErrorNotification } from '@/utils';
import { TReachedResponse } from '@/api/types';
import { TActionProgressResponse } from '@/components/ActionProgress/domains/types';
import { EActionProgressStatus } from '@/components/ActionProgress/domains/constants';
import { DELAY_FOR_CLOSING_ACTION_PROGRESS } from '@/stores/actionProgress/constants';

import {
  TActionProgressStore,
  TActionProgressStateProgress,
  TStartFetchingParams,
} from './types';

export const useActionProgressStore = defineStore(
  'actionProgress',
  (): TActionProgressStore => {
    const progresses = ref<Record<string, TActionProgressStateProgress>>({});

    const fetchProgress = ({ id }: { id: string }) => {
      getActionProgress({ id })
        .then((response: TReachedResponse<TActionProgressResponse>) => {
          const {
            currentAmount,
            domainError,
            status,
            total,
          } = response.data;

          if (status === EActionProgressStatus.completed || status === EActionProgressStatus.failed) {
            clearInterval(progresses.value[id].intervalId as ReturnType<typeof setInterval>);
            progresses.value[id].intervalId = null;

            // Чтобы автоматически скрыть уведомление о распределении рейса используем setTimeout
            setTimeout(() => {
              resetProgress({ id });
            }, DELAY_FOR_CLOSING_ACTION_PROGRESS);
          }

          progresses.value[id] = {
            ...progresses.value[id],
            currentAmount,
            domainError,
            status,
            total,
          };
        })
        .catch(() => {
          resetProgress({ id });
          showErrorNotification();
        });
    };

    const startFetching = ({
      id,
      component,
      componentProps = {},
      interval = 3000,
    }: TStartFetchingParams) => {
      progresses.value[id] = {
        currentAmount: 0,
        domainError: null,
        intervalId: setInterval(() => fetchProgress({ id }), interval),
        status: EActionProgressStatus.pending,
        title: '',
        total: 0,
        component,
        componentProps,
      };

      fetchProgress({ id });
    };

    const resetProgress = ({ id }: { id: string }) => {
      if (progresses.value[id]) {
        clearInterval(progresses.value[id].intervalId as ReturnType<typeof setInterval>);
        delete progresses.value[id];
      }
    };

    return {
      progresses,

      fetchProgress,
      resetProgress,
      startFetching,
    };
  },
);
