export enum ECommonSchemaItemType {
  default = 'default',
  submenu = 'submenu',
  withDivider = 'withDivider',
}

export enum ECommonSchemaItemLabels {
  orders = 'orders',
  digitalQueues = 'digitalQueues',
  shipments = 'shipments',
  executorsLookupsFeed = 'executorsLookupsFeed',
  tenders = 'tenders',
  employees = 'employees',
  contractors = 'contractors',
  vehicles = 'vehicles',
  partnerships = 'partnerships',
  partnershipResources = 'partnershipResources',
  executionRequests = 'executionRequests',
  lookupStrategies = 'lookupStrategies',
  priceLists = 'priceLists',
  directions = 'directions',
  matrices = 'matrices',
  references = 'references',
  quotas = 'quotas',
  reportTemplates = 'reportTemplates',
  transportationCalculator = 'transportationCalculator',
  extraServices = 'extraServices',
  operationalDocuments = 'operationalDocuments',
  approvals = 'approvals',
  runsWatching = 'runsWatching',
}

export enum EShipperRunsLabels {
  current = 'current',
  planned = 'planned',
  finished = 'finished',
  incidents = 'incidents',
}

export enum ECommonSchemaItemKeys {
  orders = 'orders',
  digitalQueues = 'digital_queues',
  shipments = 'shipments',
  executorsLookupsFeed = 'executors_lookups_feed',
  tenders = 'tenders',
  employees = 'employees',
  contractors = 'contractors',
  vehicles = 'vehicles',
  partnerships = 'partnerships',
  partnershipResources = 'partnership_resources',
  executionRequests = 'execution_requests',
  lookupStrategies = 'lookup_strategies',
  priceLists = 'price_lists',
  directions = 'directions',
  matrices = 'matrices',
  references = 'references',
  quotas = 'quotas',
  reportTemplates = 'report_templates',
  transportationCalculator = 'transportation_calculator',
  extraServices = 'extra_services',
  operationalDocuments = 'operational_documents',
  approvals = 'approvals',
  shipperRuns = 'shipper_runs',
}

export enum EShipperRunsKeys {
  current = 'current',
  planned = 'planned',
  finished = 'finished',
  incidents = 'incidents',
}

export enum ETendersKeys {
  tenders = 'tenders',
  tendersMailings = 'tenders_mailings',
}
