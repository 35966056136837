<template>
  <div class="password-input" :class="className">
    <Input
      ref="inputRef"
      :type="isVisible ? EInputTypes.text : EInputTypes.password"
      :placeholder="placeholder"
      :value="value"
      :disabled="disabled"
      :size="size"
      :autocomplete="autocomplete"
      :hasBorder="false"
      @input="onInput"
      @change="$emit('change', $event)"
      @paste="$emit('paste', $event)"
      @keyup.enter="$emit('enter', $event)"
      @keydown="$emit('keydown', $event)"
      @blur="blur"
      @focus="focus"
    />
    <button
      type="button"
      test-id="button"
      class="password-input__button"
      @click="changeVisibility"
    >
      {{ isVisible ? tt('shared.action.hide') : tt('shared.action.show') }}
    </button>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  computed,
  ref,
} from 'vue';
import type { PropType } from 'vue';

import { ESize, EInputTypes } from '@/ui/types';
import tt from '@/i18n/utils/translateText';

import Input from '../Input/index.vue';

export default defineComponent({
  name: 'PasswordInput',
  components: { Input },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    /** для задания размеров инпута */
    size: {
      type: String as PropType<ESize>,
      default: ESize.medium,
    },
    /** для подсветки инпута при не валидных данных */
    isInvalid: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
  },
  emits: [
    'input',
    'change',
    'enter',
    'paste',
    'keydown',
    'blur',
    'focus',
    'update:value',
  ],
  setup(props, { emit, expose }) {
    const isDisabled = computed(() => props.disabled);
    const isVisible = ref(false);
    const inputRef = ref<HTMLInputElement>();
    const isFocused = ref<boolean>(false);
    const className = computed(() => {
      let resultClassName = isDisabled.value ? 'password-input_disabled' : '';

      switch (props.size) {
        case ESize.small: {
          resultClassName += ' password-input_small';
          break;
        }
        case ESize.large: {
          resultClassName += ' password-input_large';
          break;
        }
        default: {
          resultClassName += ' password-input_medium';
          break;
        }
      }

      if (props.isInvalid) {
        resultClassName += ' password-input_invalid';
      }

      if (isFocused.value) {
        resultClassName += ' password-input_focused';
      }

      return resultClassName;
    });

    const changeVisibility = () => {
      if (props.disabled) return;
      isVisible.value = !isVisible.value;
    };

    const onInput = (event: Event) => {
      const targetValue = (event.target as HTMLInputElement).value;
      emit('update:value', targetValue);
      emit('input', event);
    };

    const focus = (event: Event) => {
      isFocused.value = true;
      emit('focus', event);
    };

    const blur = (event: Event) => {
      isFocused.value = false;
      emit('blur', event);
    };

    const onFocus = () => {
      inputRef.value?.focus();
    };

    expose({ onFocus });

    return {
      className,
      isDisabled,
      isVisible,
      inputRef,

      EInputTypes,

      onInput,
      focus,
      blur,
      changeVisibility,
      tt,
    };
  },
});
</script>
<style lang="scss" src="./styles.scss" />
