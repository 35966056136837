import { computed } from 'vue';

import tt from '@/i18n/utils/translateText';
import { canCreateSubscriptionsByRole } from '@/domains/permissions/role/subscriptions';
import useStore from '@/store/useStore';
import { getShortPlan } from '@/contexts/billingContext/domain/getShortPlan';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { EBillingPlanCode, EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';
import { formatDate } from '@/utils/dateUtils';
import { DATE_FORMAT } from '@/constants';
import { TSubscription } from '@/types';

import { BILLING_STARTING_DATE } from '../domain/constants';

export const useView = () => {
  const store = useStore();

  const isBillingValidFrom1MarchAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.billingValidFrom1March));

  const isBillingCarrierStartPlanAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.billingCarrierStartPlan),
  );

  const isBillingEditingPlanAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.billingEditingPlan),
  );

  const currentTenantInfo = computed(() => store.state.tenants.currentTenantInfo);
  const subscriptionRequest = computed(() => currentTenantInfo.value?.subscriptionRequest);

  const subscriptions = computed(() => currentTenantInfo.value?.subscriptions || []);

  const subscriptionWithPendingStatus = computed(
    () => subscriptions.value.find(
      (subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.pending,
    ),
  );

  const shortPlan = computed(() => {
    if (subscriptionWithPendingStatus.value?.planCode) {
      return getShortPlan(subscriptionWithPendingStatus.value.planCode);
    }

    if (subscriptionRequest.value?.planCode) {
      return getShortPlan(subscriptionRequest.value.planCode);
    }

    return '';
  },
  );

  const hasCarrierStartSubscription = computed(
    () => subscriptions.value.find(
      (subscription: TSubscription) => subscription.planCode === EBillingPlanCode.carrierStart,
    ),
  );

  const isCarrierStartSubscription = computed(
    () => isBillingCarrierStartPlanAvailable.value && hasCarrierStartSubscription.value,
  );

  const сanCreateSubscriptions = computed(() => canCreateSubscriptionsByRole());

  const isCurrentTenantWithoutSubscription = computed(() => !subscriptions.value.length && !subscriptionRequest.value);

  const showPlanSelectionButton = computed(
    () => сanCreateSubscriptions.value && (isCurrentTenantWithoutSubscription.value || (
      isBillingEditingPlanAvailable.value && isCarrierStartSubscription.value)
    ),
  );

  const isCargoOwnerSubscription = computed(
    () => !!(subscriptionWithPendingStatus.value?.planCode?.startsWith(EBillingPlanCode.cargoOwner)
    || subscriptionRequest.value?.planCode?.startsWith(EBillingPlanCode.cargoOwner)),
  );

  const expectedSubscriptionDate = computed(() => {
    if (isBillingValidFrom1MarchAvailable.value) {
      if (subscriptionWithPendingStatus.value) {
        return formatDate(subscriptionWithPendingStatus.value.subscriptionAt || '', { outputFormat: DATE_FORMAT.dayMonthYear });
      }
      return formatDate(subscriptionRequest.value?.subscriptionAt || '', { outputFormat: DATE_FORMAT.dayMonthYear });
    }
    return BILLING_STARTING_DATE;
  });

  // TODO после выпиливания ФФ billing_editing_plan удалить infoForCarrierStartSubscriptionByContactingSupport
  const paywallMessageForCarrierStartSubscription = computed(() => (
    isBillingEditingPlanAvailable.value
      ? tt('billing.paywallMessage.infoForCarrierStartSubscription')
      : tt('billing.paywallMessage.infoForCarrierStartSubscriptionByContactingSupport')
  ));

  return {
    showPlanSelectionButton,
    сanCreateSubscriptions,
    subscriptionRequest,
    isBillingValidFrom1MarchAvailable,
    shortPlan,
    isCargoOwnerSubscription,
    expectedSubscriptionDate,
    subscriptionWithPendingStatus,
    isCarrierStartSubscription,
    paywallMessageForCarrierStartSubscription,
  };
};
